<template>
    <div>
        <b-modal id="modal-edit-bank-account" cancel-variant="outline-secondary" :ok-title="$t('Update')"
            :cancel-title="$t('Close')" ok-variant="primary" centered :title="$t('Update bank account')" @ok="confirmUpdate"
            @hidden="resetModal()">
            <!-- form -->
            <validation-observer ref="updateBankModal">
                <b-form method="POST" class="auth-reset-password-form mt-2" @submit.prevent="validationForm">
                   <b-form-group label-for="bank_id">
                        <label class="mb-1">{{ $t('Bank') }}</label>
                        <validation-provider #default="{ errors }" name="type">
                            <v-select v-model="bank.bank_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="bankOptions" :reduce="val => val.id" label="bank_name"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

					<b-form-group
						label-for="link"
					>
						<label class="mb-1">{{ $t('Số tài khoản') }}</label>
						<validation-provider
							#default="{ errors }"
							name="bank_account"
						>
							<b-form-input
								id="link"
								v-model="bank.bank_account"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

                    <b-form-group label-for="status">
                        <label class="mb-1">{{ $t('Status') }}</label>
                        <validation-provider #default="{ errors }" name="status">
                            <v-select v-model="bank.status" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="statusOptions" :reduce="val => val.value" label="label"/>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-form>
            </validation-observer>

        </b-modal>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
    BForm, BFormGroup, BFormInput, BFormFile, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'

export default {
    components: {
        BForm, BFormGroup, BFormInput, BFormFile, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
        vSelect,
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    props: {
        bankAccountDetail: {
            type: Object,
            default: null,
        },
		bankOptions: {
			type: Array,
            default: [],
		},
		statusOptions: {
			type: Array,
            default: [],
		}
    },
    data() {
        return {
            bank: {
                id: '',
                bank_id: '',
                bank_account: '',
                status: ''
            },
        }
    },
    watch: {
        bankAccountDetail: {
            handler(newVal) {
                if (newVal) {
                    this.bank = {
                        id: newVal.id,
                        bank_id: newVal.bank_id,
                        status: newVal.stat,
                        bank_account: newVal.bank_account,
                    };
                }
            },
            deep: true,
        },
    },
    methods: {
        validationForm() {
            this.$refs.updateBankModal.validate().then(success => {
                if (success) {
                    this.updateBank(this.bank)
                }
            })
        },
        async updateBank(bankData) {
            await this.$store.dispatch('payment-bank-account/updateBankAccount', bankData)
                .then(response => {
                    if (response.status === 200) {
                        if (response.data.code == '00') {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message || 'success',
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                },
                            })
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    this.$bvModal.hide('modal-edit-bank-account')
                                    this.resetModal()
                                }, '1000')
                            })
                            this.$emit('refetch-data')
                        } else {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger',
                                },
                            })
                        }
                    }
                })
        },
        resetModal() {
            this.bank = {
                id: '',
                bank_id: '',
                bank_account: '',
                status: '',
            };
            this.$emit("update:bankAccountDetail", null);
        },
        confirmUpdate(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.validationForm()
        }
    },
    setup() {},
}
</script>